import React from "react";
import { AuthDataContext } from "./context";
import { useAuthHook } from "../auth/hooks";
import DashboardIcon from "../../components/icons/dashboardIcon";
import ReservationIcon from "../../components/icons/reservationIcon";
import InfoIcon from "../../components/icons/infoIcon";
import DashboardHome from "../../pages/dashboard/contents/home";
import ParametreContent from "../../pages/dashboard/contents/parametre";
import ClientsContent from "../../pages/dashboard/contents/clients";
import ProjetsContent from "../../pages/dashboard/contents/projects";
import { useStateBasicCryptSave } from "../basicdata/hooks";
import { getAdmins, getClients, getEtudes, getProjets } from "./functions";
import { AdminObject, ClientObject, EtudeObject, ProjetObject } from "../../types/models";
import PageDataLoading from "../../pages/pageDataLoading";
import ParamtreIcon from "../../components/icons/parametreIcon";
import UsersIcon from "../../components/icons/usersIcons";
import UsersContent from "../../pages/dashboard/contents/users";

const AuthDataProvider = ({ children = <div /> }) => {
  const { connected, admin } = useAuthHook();
  /// Variables
  const [admins, setAdmins] = useStateBasicCryptSave(
    "admins",
    Array<typeof AdminObject>()
  );
  const [clients, setClients] = useStateBasicCryptSave(
    "clients",
    Array<typeof ClientObject>()
  );
  const [projets, setProjets] = useStateBasicCryptSave(
    "projets",
    Array<typeof ProjetObject>()
  );
  const [etudes, setEtudes] = useStateBasicCryptSave(
    "etudes",
    Array<typeof EtudeObject>()
  );
  const [cli, setCli] = React.useState(false);
  const [pro, setPro] = React.useState(false);
  const [etu, setEtu] = React.useState(false);

  const sidebarItems = [
    {
      img: DashboardIcon,
      title: "Dashboard",
      url: "",
      slug: "dashboard",
      content: <DashboardHome />,
    },
    {
      img: ReservationIcon,
      title: "Projets",
      url: "projets",
      slug: "projets",
      content: <ProjetsContent />,
    },
    {
      img: InfoIcon,
      title: "Clients",
      url: "clients",
      slug: "clients",
      content: <ClientsContent />,
    },
    {
      img: UsersIcon,
      title: "Utilisateurs",
      url: "utilisateurs",
      slug: "utilisateurs",
      content: <UsersContent />,
    },
    {
      img: ParamtreIcon,
      title: "Parametre",
      url: "parametre",
      slug: "parametre",
      content: <ParametreContent />,
    },
  ];
  function refreshClients() {
    getClients(function (data) {
      setClients(data.data);
      setCli(true);
    });
  }
  function refreshProjets() {
    getProjets(function (data) {
      setProjets(data.data);
      setPro(true);
    });
  }
  function refreshEtudes() {
    getEtudes(function (data) {
      setEtudes(data.data);
      setEtu(true);
    });
  }
  function refreshAdmins() {
    if (admin.admin_role === "Principal") {
      getAdmins(function (data) {
        setAdmins(data.data);
      });
    }
  }
  /// UseEffects
  React.useEffect(() => {
    refreshProjets();
    refreshEtudes();
    refreshClients();
    refreshAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AuthDataContext.Provider
      value={{
        sidebarItems,
        admins,
        clients,
        etudes,
        projets,
        refreshClients,
        refreshProjets,
        refreshEtudes,
        refreshAdmins,
      }}
    >
      {connected ? (
        cli && pro && etu ? (
          children
        ) : (
          <PageDataLoading />
        )
      ) : (
        children
      )}
    </AuthDataContext.Provider>
  );
};
///<PageDataLoading />
export default AuthDataProvider;
