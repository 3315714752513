import React from "react";
import { AuthContext } from "./context";
import { AdminObject } from "../../types/models";
import { useStateBasicCryptSave } from "../basicdata/hooks";
import { useAuthHook } from "./hooks";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

const AuthProvider = ({ children = <div /> }) => {
  const navigate = useNaviLoadBoth;
  /// Variables
  const [admin, setAdmin] = useStateBasicCryptSave("admin_data", AdminObject);

  function setConnectionData(data: any) {
    setAdmin(data.admin);
  }
  function logOut() {
    setAdmin(AdminObject);
    navigate("/");
  }
  return (
    <AuthContext.Provider
      value={{
        logOut,
        setConnectionData,
        connected: admin.admin_id !== "",
        admin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthRequire = ({ children = <div /> }) => {
  const { connected } = useAuthHook();
  const navigate = useNaviLoadBoth;
  if (connected) {
    return children;
  } else {
    navigate("/signin");
    return <div />;
  }
};

export default AuthProvider;
