import React from "react";
import Table from "./table";
import { useModalHook } from "../../../../functions/useModalHook";

const ClientsContent = () => {
  const { setModal } = useModalHook();
  return (
    <div className="flex flex-1 flex-col gap-5">
      <div className="flex flex-row justify-between items-center">
        <span className="flex text-[22px] font-medium">
          Liste de tous les Projets
        </span>
        <button
          onClick={() => {
            setModal("create_client");
          }}
          className="flex px-12 py-2 bg-primary hover:bg-hover active:bg-active rounded-md"
        >
          <span className="flex text-white">Nouveau client</span>
        </button>
      </div>
      <Table />
    </div>
  );
};

export default ClientsContent;
