import React from "react";
import TableDataAutoList from "../../../../components/array";
import { useAuthDataHook } from "../../../../contexts/authData/hooks";
import {
  RemoveClient,
  SwitchClientState,
} from "../../../../contexts/client/functions";
import { useModalHook } from "../../../../functions/useModalHook";
import { useModalsHook } from "../../../../contexts/modals/hooks";
import { useAuthHook } from "../../../../contexts/auth/hooks";
import { useNavigate } from "react-router-dom";

const Table = () => {
  const { admin } = useAuthHook();
  const {
    clients,
    projets,
    etudes,
    refreshClients,
    refreshEtudes,
    refreshProjets,
  } = useAuthDataHook();
  const { setModal } = useModalHook();
  const { setData } = useModalsHook();
  const navigate = useNavigate();
  const columns = [
    {
      label: "Client",
      slug: "customer",
    },
    {
      label: "Projets",
      slug: "projects",
    },
    {
      label: "Etudes",
      slug: "etudes",
    },
    {
      label: "Création",
      slug: "creation",
    },
    {
      label: "Référant",
      slug: "view",
    },
    {
      label: "tat",
      slug: "state",
    },
    {
      label: "Modifier",
      slug: "edit",
    },
    {
      label: "Supprimer",
      slug: "remove",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        viewIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
          </svg>
        }
        onView={function (data) {
          navigate(data.client.client_id);
        }}
        onEdit={function (data) {
          setData({
            client: data.client,
          });
          setModal("update_client");
        }}
        onToogleState={function (data, bool) {
          SwitchClientState(
            { id: admin.admin_id, client_id: data.client.client_id },
            function (reponse) {
              refreshClients();
              refreshProjets();
              refreshEtudes();
            }
          );
        }}
        onRemove={function (data) {
          setData({
            confirm: function () {
              RemoveClient(
                { id: admin.admin_id, client_id: data.client.client_id },
                function (reponse) {
                  refreshClients();
                  refreshProjets();
                  refreshEtudes();
                }
              );
            },
          });
          setModal("confirm_action");
        }}
        rows={clients.map((client) => ({
          client,
          customer: client.client_name,
          projects: projets
            .filter((p) => p.client_id === client.client_id)
            .length.toString(),
          etudes: etudes
            .filter((e) => e.client_id === client.client_id)
            .length.toString(),
          creation: client.client_created_date
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/"),
          state: client.client_state.actived,
        }))}
      />
    </div>
  );
};

export default Table;
