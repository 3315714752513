import React from "react";
import TableDataAutoList from "../../../../components/array";
import { useModalHook } from "../../../../functions/useModalHook";
import { useModalsHook } from "../../../../contexts/modals/hooks";
import { useLogsHook } from "../../../../contexts/logs/hooks";
const Table = () => {
  const { setModal } = useModalHook();
  const { setData } = useModalsHook();
  const { logs } = useLogsHook();
  const columns = [
    {
      label: "Action",
      slug: "action",
    },
    {
      label: "Jour",
      slug: "day",
    },
    {
      label: "Heure",
      slug: "hour",
    },
    {
      label: "Description",
      slug: "description",
      maxValueLenght: 300,
      maxWidth: 250,
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        onView={function (data) {
          setData({
            etude: data,
          });
          setModal("view_etude");
        }}
        rows={logs
          .map((log) => ({
            log,
            action: log.action,
            day: log.created_date.split("T")[0].split("-").reverse().join("/"),
            hour: log.created_date.split("T")[1].split("+")[0],
            description: log.description,
          }))
          .sort(
            (a, b) =>
              parseInt(b.day.split("/").join("") + b.hour.split(":").join("")) -
              parseInt(a.day.split("/").join("") + a.hour.split(":").join(""))
          )}
      />
    </div>
  );
};

export default Table;
