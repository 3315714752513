import { AxiosPostUrl } from "../../functions/axiosfunctions";

export function sendSigninData(data: Object, cb = function (reponse: any) {}) {
  AxiosPostUrl(
    "/administration/admin/signin",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}
