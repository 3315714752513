import React from "react";
import TableDataAutoList from "../../../../components/array";
import { useProjetHook } from "../../../../contexts/projet/hooks";
import { RemoveEtude } from "../../../../contexts/etude/functions";
import { useAuthDataHook } from "../../../../contexts/authData/hooks";
import { useModalHook } from "../../../../functions/useModalHook";
import { useModalsHook } from "../../../../contexts/modals/hooks";
import { useAuthHook } from "../../../../contexts/auth/hooks";

const Table = () => {
  const { admin } = useAuthHook();
  const { setModal } = useModalHook();
  const { setData } = useModalsHook();
  const { refreshClients, refreshProjets, refreshEtudes } = useAuthDataHook();
  const { etudes } = useProjetHook();
  const columns = [
    {
      label: "Télécharger",
      slug: "view",
    },
    {
      label: "Titre",
      slug: "title",
    },
    {
      label: "Description",
      slug: "description",
    },
    {
      label: "Documents",
      slug: "docs",
    },
    {
      label: "Début",
      slug: "start",
    },
    {
      label: "fin",
      slug: "end",
    },
    {
      label: "Ajouter le",
      slug: "date",
    },
    {
      label: "Supprimer",
      slug: "remove",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        viewIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-7 h-7 text-primary hover:text-hover transition-all"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
            />
          </svg>
        }
        onView={function (data) {
          setData({
            etude: data,
          });
          setModal("view_etude");
        }}
        onRemove={function (data) {
          setData({
            confirm: function () {
              RemoveEtude(
                { id: admin.admin_id, etude_id: data.etude.etude_id },
                function (reponse) {
                  refreshClients();
                  refreshProjets();
                  refreshEtudes();
                }
              );
            },
          });
          setModal("confirm_action");
        }}
        rows={etudes.map((e) => ({
          etude: e,
          title: e.etude_title,
          description: e.etude_descripton,
          start: e.etude_begin_date.split("T")[0],
          end: e.etude_final_date.split("T")[0],
          date: e.etude_created_date.split("T")[0],
          docs: e.etude_data.length,
        }))}
      />
    </div>
  );
};

export default Table;
