import React from "react";
import { CreateReferantContext } from "./context";
import { sendReferantSignupData } from "./functions";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { isEmail } from "../../constants";
import { useAuthDataHook } from "../authData/hooks";
import { useModalHook } from "../../functions/useModalHook";
import { useModalsHook } from "../modals/hooks";
import { useAuthHook } from "../auth/hooks";

const CreateReferantProvider = ({ children = <div /> }) => {
  const { admin } = useAuthHook();
  const { closeModal } = useModalHook();
  const { data, setData } = useModalsHook();
  const { refreshClients, refreshProjets, refreshEtudes } = useAuthDataHook();
  const navigate = useNaviLoadBoth;
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    referant_name: "",
    referant_email: "",
    referant_password: "",
  });
  const [errorState, setErrorState] = React.useState({
    referant_name: { state: false, msg: "" },
    referant_email: { state: false, msg: "" },
    referant_password: { state: false, msg: "" },
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...f, [slug]: value }));
      setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
    };
  }
  function checkFormData() {
    let errorOccured = false;
    // referant_name
    if (formData.referant_name.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, referant_name: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        referant_name: { state: false, msg: "" },
      }));
    }
    // referant_email
    if (
      formData.referant_email.length === 0 ||
      !isEmail(formData.referant_email)
    ) {
      errorOccured = true;
      let msg = "adresse email incorrecte";
      setErrorState((e) => ({ ...e, referant_email: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        referant_email: { state: false, msg: "" },
      }));
    }
    // referant_password
    if (formData.referant_password.length <= 8) {
      errorOccured = true;
      let msg = "mot de passe trop court, minimum 8 caracteres";
      setErrorState((e) => ({
        ...e,
        referant_password: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        referant_password: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function submitForm() {
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setisLoading(true);
    setData({ ...data, isclosable: false });
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    sendReferantSignupData(
      {
        id: admin.admin_id,
        client_id: data.client.client_id,
        ...formData,
      },
      function (reponse) {
        setisLoading(false);
        setData({ ...data, isclosable: true });
        if (reponse.success) {
          refreshClients();
          refreshProjets();
          refreshEtudes();
          closeModal();
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
          if (reponse?.redirection) {
            navigate(reponse.redirection);
          }
        }
      }
    );
  }
  return (
    <CreateReferantContext.Provider
      value={{ formData, isLoading, errorState, onValueChange, submitForm }}
    >
      {children}
    </CreateReferantContext.Provider>
  );
};

export default CreateReferantProvider;
