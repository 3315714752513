import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { hoverColor } from "../../constants";
import { ThemeProvider, createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: hoverColor,
    },
  },
});
const DateInput = ({
  onChange = (date: string) => {},
  errorState = { state: false, msg: "" },
  ...args
}) => {
  const [value, setValue] = React.useState<string | null>(null);
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateField
          {...args}
          className="self-stretch"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            newValue && onChange(newValue);
          }}
          color="warning"
          helperText={errorState.msg.length !== 0 ? errorState.msg : null}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DateInput;
