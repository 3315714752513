import { AxiosGetUrl } from "../../functions/axiosfunctions";

export function getAdmins(cb = function (reponse: any) {}) {
  AxiosGetUrl("/administration/admin/all", (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}


export function getClients(cb = function (reponse: any) {}) {
  AxiosGetUrl("/administration/client/all", (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}

export function getProjets(cb = function (reponse: any) {}) {
  AxiosGetUrl("/administration/projet/all", (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}

export function getEtudes(cb = function (reponse: any) {}) {
  AxiosGetUrl("/administration/etude/all", (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}
