import React from "react";
import BlocItem from "./bloc";
import AddProject from "./addProject";
import { useAuthDataHook } from "../../../../../contexts/authData/hooks";

const BlocsRow = () => {
  const { clients, projets, etudes } = useAuthDataHook();
  return (
    <div className="grid w-fit flex-row sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-5">
      <BlocItem title="Clients" number={clients.length.toString()} />
      <BlocItem  title="Projets" number={projets.length.toString()}/>
      <BlocItem title="Etudes" number={etudes.length.toString()}/>
      <AddProject />
    </div>
  );
};

export default BlocsRow;
