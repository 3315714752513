import React from "react";
import { CreateProjetContext } from "./context";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { useAuthDataHook } from "../authData/hooks";
import { addNewProjet } from "./functions";
import { useModalHook } from "../../functions/useModalHook";
import { useModalsHook } from "../modals/hooks";
import { useAuthHook } from "../auth/hooks";

const CreateProjetProvider = ({ children = <div /> }) => {
  const {admin} = useAuthHook();
  const { closeModal } = useModalHook();
  const { data, setData } = useModalsHook();
  const { refreshClients, refreshProjets, refreshEtudes } = useAuthDataHook();
  const navigate = useNaviLoadBoth;
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    client_id: "",
    projet_title: "",
    projet_description: "",
  });
  const [errorState, setErrorState] = React.useState({
    client_id: { state: false, msg: "" },
    projet_title: { state: false, msg: "" },
    projet_description: { state: false, msg: "" },
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...f, [slug]: value }));
      setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
    };
  }
  function checkFormData() {
    let errorOccured = false;
    // admin_name
    if (formData.client_id.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, client_id: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, client_id: { state: false, msg: "" } }));
    }
    // admin_email
    if (formData.projet_title.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, projet_title: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        projet_title: { state: false, msg: "" },
      }));
    }
    if (formData.projet_description.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({
        ...e,
        projet_description: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        projet_description: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function submitForm() {
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setisLoading(true);
    setData({ ...data, isclosable: false });
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    addNewProjet(
      { id: admin.admin_id, 
        client_id: formData.client_id,
        projet_title: formData.projet_title,
        projet_description: formData.projet_description,
      },
      function (reponse) {
        setisLoading(false);
        setData({ ...data, isclosable: true });
        if (reponse.success) {
          refreshClients();
          refreshProjets();
          refreshEtudes();
          closeModal();
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
          if (reponse?.redirection) {
            navigate(reponse.redirection);
          }
        }
      }
    );
  }
  return (
    <CreateProjetContext.Provider
      value={{ formData, isLoading, errorState, onValueChange, submitForm }}
    >
      {children}
    </CreateProjetContext.Provider>
  );
};

export default CreateProjetProvider;
