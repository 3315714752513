import { createContext } from "react";

export const CreateReferantContext = createContext({
  isLoading: false,
  formData: {
    referant_name: "",
    referant_email: "",
    referant_password: "",
  },
  onValueChange: (slug: string) => function (value: any) {},
  submitForm: function () {},
  errorState: {
    referant_name: { state: false, msg: "" },
    referant_email: { state: false, msg: "" },
    referant_password: { state: false, msg: "" },
  },
});
