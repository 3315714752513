import { createContext } from "react";
import {
  AdminObject,
} from "../../types/models";

export const AuthContext = createContext({
  connected: false,
  admin: AdminObject,
  setConnectionData: (data: any) => {},
  logOut: () => {},
});
