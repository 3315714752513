import { AxiosPostUrl } from "../../functions/axiosfunctions";

export function SwitchClientState(
  data: Object,
  cb = function (reponse: any) {}
) {
  AxiosPostUrl(
    "/administration/client/state/switch",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}

export function RemoveClient(
  data: Object,
  cb = function (reponse: any) {}
) {
  AxiosPostUrl(
    "/administration/client/remove",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}

export function RemoveReferant(
  data: Object,
  cb = function (reponse: any) {}
) {
  AxiosPostUrl(
    "/administration/client/referant/remove",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}
