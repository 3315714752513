import { createContext } from "react";

export const CreateAdminContext = createContext({
  isLoading: false,
  formData: {
    admin_name: "",
    admin_email: "",
    admin_password: "",
  },
  onValueChange: (slug: string) => function (value: any) {},
  submitForm: function () {},
  errorState: {
    admin_name: { state: false, msg: "" },
    admin_email: { state: false, msg: "" },
    admin_password: { state: false, msg: "" },
  },
});
