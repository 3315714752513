import React from "react";
import TextInput from "../../../../components/inputs/input";
import PasswordInput from "../../../../components/inputs/password";
import { useCreateAdminHook } from "../../../createUser/hooks";

const Form = () => {
  const { onValueChange, errorState } = useCreateAdminHook();
  return (
    <div className="flex flex-col gap-4 w-full py-3">
      <TextInput
        label={"Nom & Prenom"}
        errorState={errorState.admin_name}
        onChange={onValueChange("admin_name")}
      />
      <div className="flex flex-col gap-2">
        <span className="flex text-[10px]">
          L'adresse email et le mot de passe serviront d'identifiants de
          connexion pour l'utilisateur sur l'administration
        </span>
        <TextInput
          label={"Email"}
          errorState={errorState.admin_email}
          onChange={onValueChange("admin_email")}
        />
      </div>
      <PasswordInput
        label={"Mot de passe"}
        autoGenerate={true}
        errorState={errorState.admin_password}
        onChange={onValueChange("admin_password")}
      />
      <span className="flex font-normal text-[13px] gap-3">
        <span className="flex text-[25px]">⚠️</span>
        <span className="flex">
          Après avoir terminé le processus d'enregistrement, les identifiants de
          connexion de l'utilisateur lui seront automatiquement envoyés par e-mail.
        </span>
      </span>
    </div>
  );
};

export default Form;
