import React from "react";
import { ModalContainer } from "../../provider";
import Form from "./form";
import { useUpdateClientHook } from "../../../updateClient/hooks";
import UpdateClientProvider from "../../../updateClient/provider";

const UpdateClient = () => {
  const { isLoading, submitForm } = useUpdateClientHook();
  return (
    <div className="flex flex-col max-h-[80vh] items-center pb-6 pt-3 px-10 gap-3 bg-white rounded-md w-[500px] shadow-md ">
      <span className="flex text-primary font-semibold text-[20px]">
        Modifier le client
      </span>
      <Form />
      <button
        onClick={!isLoading ? submitForm : void {}}
        className="flex px-12 py-2 bg-primary hover:bg-hover active:bg-active rounded-md"
        style={{
          background: isLoading ? "rgba(249, 154, 14, 0.75)" : undefined,
        }}
      >
        <span className="flex text-white">
          {isLoading ? "chargement..." : "Enregister le client"}
        </span>
      </button>
    </div>
  );
};

const UpdateClientFromModel = ({ size = { h: 0, w: 0 } }) => {
  return (
    <ModalContainer {...{ size }}>
      <UpdateClientProvider>
        <UpdateClient />
      </UpdateClientProvider>
    </ModalContainer>
  );
};

export default UpdateClientFromModel;
