import React from "react";
import Table from "./table";
import { useModalHook } from "../../../../functions/useModalHook";
import { useClientHook } from "../../../../contexts/client/hook";
import { useModalsHook } from "../../../../contexts/modals/hooks";

const ClientContent = () => {
  const { setData } = useModalsHook();
  const { client, referant } = useClientHook();
  const { setModal } = useModalHook();
  return (
    <div className="flex flex-1 flex-col gap-5">
      <div className="flex flex-row justify-between items-center">
        <span className="flex text-[23px] font-semibold">
          {client.client_name}
        </span>
        <button
          onClick={() => {
            if (!!!referant?.client_id) {
              setData({
                client,
                referant,
              });
              setModal("create_referant");
            } else {
              alert(
                "Il est impossible d'avoir plus d'un référant par client!"
              );
            }
          }}
          className="flex px-12 py-2 bg-primary hover:bg-hover active:bg-active rounded-md"
        >
          <span className="flex text-white">Nouveau référant</span>
        </button>
      </div>
      <Table />
    </div>
  );
};

export default ClientContent;
