import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { getPropValue } from "../../functions/getPropValue";
import { IconButton, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Visibility } from "@mui/icons-material";

interface Column {
  align?: "center" | "left" | "right" | "inherit" | "justify" | undefined;
  minWidth?: string;
  maxWidth?: string | number | undefined;
  label: string;
  slug?: string;
  maxValueLenght?: number;
}

interface Row {
  [key: string]: any;
}

interface Props {
  columns: Column[];
  rows: Row[];
  maxWidth?: number | string;
  maxHeight?: number | string;
  viewIcon?: any;
  onView?: (data: any) => void;
  onEdit?: (data: any) => void;
  onRemove?: (data: any) => void;
  onToogleState?: (data: any, value: boolean) => void;
}

function TableDataAutoList({
  columns = [],
  rows = [],
  maxHeight = "auto",
  maxWidth = "100%",
  viewIcon = null,
  onView,
  onEdit,
  onRemove,
  onToogleState,
}: Props) {
  const ANY = Object();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let predefinedItem = {
    view: function ({ row = ANY, ...args }) {
      return (
        <TableCell
          sx={{ width: 25 }}
          align={"center"}
          style={{ fontWeight: "600" }}
        >
          <IconButton
            edge="end"
            color="default"
            onClick={() => {
              !!onView && onView(row);
            }}
            aria-label="edit"
            size="small"
          >
            {viewIcon ?? <Visibility />}
          </IconButton>
        </TableCell>
      );
    },
    state: function ({ row = ANY, value = ANY, ...args }) {
      return (
        <TableCell align={"center"}>
          <Switch
            size="small"
            checked={value}
            // sx={{ width: 45 }}
            onChange={(e, b) => {
              !!onToogleState && onToogleState(row, b);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </TableCell>
      );
    },
    edit: function ({ row = ANY, ...args }) {
      return (
        <TableCell sx={{ width: 45 }} align={"center"}>
          <IconButton
            edge="end"
            color="default"
            onClick={() => {
              !!onEdit && onEdit(row);
            }}
            aria-label="edit"
            size="small"
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      );
    },
    remove: function ({ row = ANY, ...args }) {
      return (
        <TableCell sx={{ width: 45 }} align={"center"}>
          <IconButton
            edge="end"
            color="default"
            onClick={() => {
              !!onRemove && onRemove(row);
            }}
            aria-label="delete"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      );
    },
  };

  return (
    <Paper
      sx={{ width: "100%", maxWidth: maxWidth, overflow: "hidden", zIndex: 0 }}
      elevation={0}
    >
      <TableContainer sx={{ maxHeight: maxHeight, zIndex: 0 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.slug}
                  align={
                    !!getPropValue(predefinedItem, column.slug)
                      ? "center"
                      : "left"
                  }
                  sx={{ width: "fit" }}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    fontWeight: "700",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length !== 0 ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column, index2) => {
                        const value = getPropValue(row, column.slug);
                        const maxValueLenght = column.maxValueLenght ?? 20;
                        if (
                          Object.keys(predefinedItem).includes(
                            column.slug ?? "mpolmpolmpolm"
                          )
                        ) {
                          const value = getPropValue(row, column.slug) ?? "";
                          let CompNent = getPropValue(
                            predefinedItem,
                            column.slug
                          );
                          return (
                            <CompNent key={index2} column={column} row={row} value={value} />
                          );
                        }
                        return (
                          <TableCell key={index2} align={column.align} style={{
                            maxWidth: column.maxWidth,
                          }}>
                            {(value ?? "").length > maxValueLenght
                              ? value.slice(0, maxValueLenght) + "..."
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell align={"center"} style={{ fontWeight: "500" }}>
                  Aucune donnee enregister !
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default TableDataAutoList;
