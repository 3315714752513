import { useNavigate } from "react-router-dom";
import { useAuthDataHook } from "../../../../contexts/authData/hooks";
import { ProjetObject } from "../../../../types/models";
import { useMuiPopup } from "../../../../components/popup";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RemoveProjet } from "../../../../contexts/projet/functions";
import { useModalHook } from "../../../../functions/useModalHook";
import { useModalsHook } from "../../../../contexts/modals/hooks";
import React from "react";
import { useAuthHook } from "../../../../contexts/auth/hooks";

const ProjectCard = ({ projet = ProjetObject }) => {
  const { admin } = useAuthHook();
  const { setModal } = useModalHook();
  const { setData } = useModalsHook();
  const [isDeleting, setisDeleting] = React.useState(false);
  const { refElement, Popup, switchMenu } = useMuiPopup<HTMLDivElement>();
  const { clients, refreshClients, refreshProjets, refreshEtudes } =
    useAuthDataHook();
  const navigate = useNavigate();
  function open() {
    navigate(projet.projet_id);
  }
  function remove() {
    setData({
      confirm: function () {
        setisDeleting(true);
        RemoveProjet(
          { id: admin.admin_id, projet_id: projet.projet_id },
          function (reponse) {
            refreshClients();
            refreshProjets();
            refreshEtudes();
            setisDeleting(false);
          }
        );
      },
    });
    setModal("confirm_action");
    switchMenu();
  }
  return (
    <div className="flex flex-col col-span-1 rounded-xl shadow bg-white relative">
      <div className="flex flex-col p-4 pb-1 gap-2">
        <span className="flex flex-1 font-medium text-[22px]">
          {projet.projet_title}
        </span>
        <span className="flex flex-1 font-medium text-[15px] text-primary/80">
          {
            clients.filter((c) => c.client_id === projet.client_id)[0]
              ?.client_name
          }
        </span>
        <span className="flex flex-1 font-medium text-[10px] text-black/50">
          Creation: {projet.projet_created_date.split("T")[0]}
        </span>
        <div ref={refElement} className="flex absolute top-1 right-1">
          <IconButton
            edge="end"
            color="default"
            onClick={switchMenu}
            aria-label="delete"
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
      <div className="flex p-3 pt-2">
        <button
          onClick={!isDeleting ? open : void {}}
          style={{
            background: isDeleting ? "rgb(58 102 172 / 0.3)" : undefined,
          }}
          className="flex transition-all flex-1 rounded-md bg-primary hover:bg-hover active:bg-active items-center justify-center py-2"
        >
          <span className="flex text-white font-medium">
            {!isDeleting ? "Ouvrir le projet" : "Suppression..."}
          </span>
        </button>
      </div>
      <Popup
        elevation={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="flex py-1">
          <button
            onClick={remove}
            className="flex py-2 px-4 hover:bg-gainsboro active:bg-gainsboro/70"
          >
            <span>Supprimer</span>
          </button>
        </div>
      </Popup>
    </div>
  );
};
//LoadingCircle
export default ProjectCard;
