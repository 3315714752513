import React from "react";
import { UpdateReferantContext } from "./context";
import { sendUpdateReferantData } from "./functions";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { useAuthDataHook } from "../authData/hooks";
import { useModalHook } from "../../functions/useModalHook";
import { useModalsHook } from "../modals/hooks";
import { useAuthHook } from "../auth/hooks";

const UpdateReferantProvider = ({ children = <div /> }) => {
  const { admin } = useAuthHook();
  const { closeModal } = useModalHook();
  const { data, setData } = useModalsHook();
  const { refreshClients, refreshProjets, refreshEtudes } = useAuthDataHook();
  const navigate = useNaviLoadBoth;
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    referant_name: "",
  });
  const [errorState, setErrorState] = React.useState({
    referant_name: { state: false, msg: "" },
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...f, [slug]: value }));
      setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
    };
  }
  function checkFormData() {
    let errorOccured = false;
    // referant_name
    if (formData.referant_name.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, referant_name: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        referant_name: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function submitForm() {
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setisLoading(true);
    setData({ ...data, isclosable: false });
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    sendUpdateReferantData(
      {
        id: admin.admin_id,
        referant_id: data.referant.referant_id,
        ...formData,
      },
      function (reponse) {
        setisLoading(false);
        setData({ ...data, isclosable: true });
        if (reponse.success) {
          refreshClients();
          refreshProjets();
          refreshEtudes();
          closeModal();
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
          if (reponse?.redirection) {
            navigate(reponse.redirection);
          }
        }
      }
    );
  }
  return (
    <UpdateReferantContext.Provider
      value={{ formData, isLoading, errorState, onValueChange, submitForm }}
    >
      {children}
    </UpdateReferantContext.Provider>
  );
};

export default UpdateReferantProvider;
