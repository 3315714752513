import React from "react";
import { useModalHook } from "../../../../../functions/useModalHook";

const AddProject = () => {
  const { setModal } = useModalHook();
  return (
    <button
      onClick={() => {
        setModal("create_projet");
      }}
      className="flex flex-col py-7 bg-primary hover:bg-hover active:bg-active text-white rounded-md max-w-[300px] justify-center items-center"
    >
      <span className="flex text-[17px] font-medium w-[40%]">
        Ajouter un nouveau projet
      </span>
    </button>
  );
};

export default AddProject;
