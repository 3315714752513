import React from "react";
import help from "../../assets/help.svg";

const BasicHeader = () => {
  return (
    <div className="flex flex-row items-center gap-[27px]">
      {/* <SwitchLangButton /> */}
      <button className="flex">
        <img className="flex" alt="" src={help} />
      </button>
    </div>
  );
};

export default BasicHeader;
