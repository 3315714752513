import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./contexts/auth/provider";
import BasicDataProvider from "./contexts/basicdata/provider";
import AuthDataProvider from "./contexts/authData/provider";
import ModalsProvider from "./contexts/modals/provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <BasicDataProvider>
        <AuthProvider>
          <AuthDataProvider>
            <ModalsProvider>
              <App />
            </ModalsProvider>
          </AuthDataProvider>
        </AuthProvider>
      </BasicDataProvider>
    </BrowserRouter>
  </React.StrictMode>
);
