import { createContext } from "react";

export const CreateEtudeContext = createContext({
  isLoading: false,
  formData: {
    client_id: "",
    projet_id: "",
    etude_title: "",
    etude_descripton: "",
    etude_begin_date: "",
    etude_final_date: "",
    etude_data: Array<any>(),
  },
  onValueChange: (slug: string) => function (value: any) {},
  addEtudeData: (data: any) => {},
  removeEtudeData: (index: number) => {},
  submitForm: function () {},
  errorState: {
    etude_title: { state: false, msg: "" },
    etude_descripton: { state: false, msg: "" },
    etude_data: { state: false, msg: "" },
    etude_begin_date: { state: false, msg: "" },
    etude_final_date: { state: false, msg: "" },
  },
});
