import React from "react";
import Table from "./table";
import { useModalHook } from "../../../../functions/useModalHook";
import { useProjetHook } from "../../../../contexts/projet/hooks";
import { useModalsHook } from "../../../../contexts/modals/hooks";

const ProjetContent = () => {
  const { client, projet } = useProjetHook();
  const { setModal } = useModalHook();
  const { setData } = useModalsHook();
  return (
    <div className="flex flex-1 flex-col gap-5">
      <div className="flex flex-row gap-3 items-center">
        <span className="flex text-[15px] font-semibold">Client :</span>
        <span className="flex text-[25px] font-semibold">
          {client.client_name}
        </span>
      </div>
      <div className="flex flex-row gap-3 items-center">
        <span className="flex text-[15px] font-semibold">Projet :</span>
        <span className="flex text-[25px] font-semibold">
          {projet.projet_title}
        </span>
      </div>
      <div className="flex flex-row justify-between items-center">
        <span className="flex text-[22px] font-medium">
          Liste de toutes les études realisées
        </span>
        <button
          onClick={() => {
            setData({
              client_id: projet.client_id,
              projet_id: projet.projet_id,
            });
            setModal("create_etude");
          }}
          className="flex px-12 py-2 bg-primary hover:bg-hover active:bg-active rounded-md"
        >
          <span className="flex text-white">Ajouter nouvelle étude</span>
        </button>
      </div>
      <Table />
    </div>
  );
};

export default ProjetContent;
