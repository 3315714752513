import React from "react";
import { BasicDataContext } from "./context";
import { getCountries, getLangs } from "./functions";
import { CountryObject, LangObject } from "../../types/models";
import { useStateBasicCryptSave } from "./hooks";
import PageDataLoading from "../../pages/pageDataLoading";

const BasicDataProvider = ({ children = <div /> }) => {
  /// Variables
  const [countries, setCountries] = useStateBasicCryptSave(
    "countries",
    Array<typeof CountryObject>()
  );
  const [localCountry, _setLocalCountry] = useStateBasicCryptSave(
    "localCountry",
    CountryObject
  );
  const [langs, setLangs] = useStateBasicCryptSave(
    "langs",
    Array<typeof LangObject>()
  );
  const [selectedLang, _setLang] = useStateBasicCryptSave("lang", LangObject);
  /// Refresh functs
  function refreshCountries() {
    getCountries(function (data) {
      setCountries(data.data);
      const localCnty = data.data.filter(
        (e: typeof CountryObject) => e.code === "CI"
      )[0];
      if (!!localCnty && localCountry.code === "") {
        _setLocalCountry(localCnty);
      }
    });
  }
  function refreshLangs() {
    getLangs(function (data) {
      setLangs(data.data);
      const lang = data.data.filter(
        (e: typeof LangObject) => e.slug === "Fr"
      )[0];
      if (!!lang && selectedLang.slug === "") {
        _setLang(lang);
      }
    });
  }
  /// setState functs
  function setLocalCountry(country = CountryObject) {
    _setLocalCountry(country);
  }
  function setLocalCountryFromCode(code = "") {
    _setLocalCountry(countries.filter((cnt) => cnt.code === code)[0]);
  }
  function setLang(lang = LangObject) {
    _setLang(lang);
  }
  /// UseEffects
  React.useEffect(() => {
    refreshCountries();
    refreshLangs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BasicDataContext.Provider
      value={{
        //Country
        countries,
        refreshCountries,
        localCountry,
        setLocalCountry,
        setLocalCountryFromCode,
        //Lang
        langs,
        refreshLangs,
        selectedLang,
        setLang,
      }}
    >
      {countries?.length !== 0 && langs?.length !== 0 ? (
        children
      ) : (
        <PageDataLoading />
      )}
    </BasicDataContext.Provider>
  );
};

export default BasicDataProvider;
