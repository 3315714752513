import React from "react";
import TextInput from "../../../../components/inputs/input";
import { useUpdateReferantHook } from "../../../updateReferant/hooks";

const Form = () => {
  const { onValueChange, errorState } = useUpdateReferantHook();
  return (
    <div className="flex flex-col gap-4 w-full py-3">
      <TextInput
        label={"Nom du referant"}
        errorState={errorState.referant_name}
        onChange={onValueChange("referant_name")}
      />
    </div>
  );
};

export default Form;
