import React from "react";
import { ModalContainer } from "../../provider";
import { useModalsHook } from "../../hooks";
import { useModalHook } from "../../../../functions/useModalHook";

const ViewEtudesAction = () => {
  const { data } = useModalsHook();
  return (
    <div className="flex flex-col min-w-[350px] items-center pb-5 pt-5 px-5 gap-3 bg-white rounded-md shadow-md ">
      <span className="flex text-black font-semibold text-[20px] w-full text-center">
        {data.etude.etude.etude_title}
      </span>
      <span className="flex text-black font-medium text-[15px] w-full text-center">
        {data.etude.etude.etude_descripton}
      </span>
      <span className="flex flex-col text-black/75 font-medium w-full text-center">
        <span className="flex text-[13px] text-center">
          Debut de l'etude:{" "}
          {data.etude.start.split("T")[0].split("-").reverse().join("/")}
        </span>
        <span className="flex text-[13px] text-center">
          Fin de l'etude:{" "}
          {data.etude.end.split("T")[0].split("-").reverse().join("/")}
        </span>
        <span className="flex text-[13px] text-center mt-2">
          Date de creation:{" "}
          {data.etude.date.split("T")[0].split("-").reverse().join("/")}
        </span>
      </span>
      <div className="flex flex-col gap-3 w-full">
        {data.etude.etude.etude_data.map((dt: any, index: number) => (
          <a href={dt.download_url} key={index} download className="flex w-full">
            <button
              onClick={function () {
                console.log(data);
              }}
              className="group flex w-full items-center gap-3 flex-row px-3 py-2 bg-primary/95 hover:bg-primary/80 active:bg-primary hover:shadow-md transition-all rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4 text-white"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="flex text-white text-[14px]">{dt.name}</span>
            </button>
          </a>
        ))}
      </div>
    </div>
  );
};

const ViewEtudesActionFromModel = ({ size = { h: 0, w: 0 } }) => {
  const { data } = useModalsHook();
  const { closeModal } = useModalHook();
  if (!data.etude) {
    setTimeout(() => {
      closeModal();
    }, 300);
  }
  return !data.etude ? (
    <></>
  ) : (
    <ModalContainer {...{ size }}>
      <ViewEtudesAction />
    </ModalContainer>
  );
};

export default ViewEtudesActionFromModel;
