import { TextField } from "@mui/material";

const TextInput = ({
  onChange = (e: string) => {},
  errorState = { state: false, msg: "" },
  multiple = false,
  rows = 1,
  ...args
}) => {
  const handleChange = (value: any) => {
    onChange(value.currentTarget.value);
  };

  return (
    <TextField
      color="warning"
      variant="outlined"
      className="flex bg-white border-none outline-none"
      error={errorState.state}
      helperText={errorState.msg.length !== 0 ? errorState.msg : null}
      onChange={handleChange}
      multiline={multiple}
      rows={multiple ? rows : 1}
      {...args}
    />
  );
};

export default TextInput;
