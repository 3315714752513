import React from "react";
import TextInput from "../../../../components/inputs/input";
import { useCreateProjetHook } from "../../../createProjet/hooks";
import AutocompleteSelectInput from "../../../../components/inputs/autoCompleteSelect";
import { useAuthDataHook } from "../../../authData/hooks";

const Form = () => {
  const { clients } = useAuthDataHook();
  const { onValueChange, errorState } = useCreateProjetHook();
  return (
    <div className="flex flex-col gap-4 w-full py-3">
      <AutocompleteSelectInput
        label={"Selectionner le client"}
        options={clients.map((c) => ({
          label: c.client_name,
          value: c.client_id,
        }))}
        errorState={errorState.client_id}
        onChange={onValueChange("client_id")}
      />
      <TextInput
        label={"Titre du projet"}
        errorState={errorState.projet_title}
        onChange={onValueChange("projet_title")}
      />
      <TextInput
        label={"Descripiton du projet"}
        multiple={true}
        rows={5}
        errorState={errorState.projet_description}
        onChange={onValueChange("projet_description")}
      />
    </div>
  );
};

export default Form;
