import React from "react";
import TextInput from "../../../../components/inputs/input";
import { useCreateEtudeHook } from "../../../createEtude/hooks";
import { useModalsHook } from "../../hooks";
import { useModalHook } from "../../../../functions/useModalHook";
import DateInput from "../../../../components/inputs/datepick";
import { primaryColor } from "../../../../constants";

const Form = () => {
  const { closeModal } = useModalHook();
  const { data } = useModalsHook();
  const {
    formData,
    isLoading,
    addEtudeData,
    removeEtudeData,
    onValueChange,
    errorState,
  } = useCreateEtudeHook();

  function pickFile() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const filesObj = e.target.files;
    const files = Object.values(filesObj);
    if (!files) return;
    if (files.length === 0) return;
    files.map((file: any) => {
      if (
        file.size < 200000000 &&
        [
          "png",
          "jpg",
          "jpeg",
          "pdf",
          "xls",
          "xlsx",
          "doc",
          "docx",
          "ppt",
          "pptx",
          "csv",
        ].includes(file.name.split(".").reverse()[0])
      ) {
        addEtudeData(file);
      } else {
        console.log("Format de fichier non autorise", file.name);
      }
      return file;
    });
  }
  function basics() {
    if (!!data.projet_id && !!data.client_id) {
      onValueChange("projet_id")(data.projet_id);
      onValueChange("client_id")(data.client_id);
    } else {
      closeModal();
    }
  }
  React.useEffect(() => {
    basics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4 w-full py-3">
      <TextInput
        label={"Titre de l'etude"}
        errorState={errorState.etude_title}
        onChange={onValueChange("etude_title")}
      />
      <TextInput
        label={"Descripiton de l'etude"}
        errorState={errorState.etude_descripton}
        onChange={onValueChange("etude_descripton")}
        multiple={true}
        rows={3}
      />
      <div className="flex flex-row gap-3">
        <DateInput
          label={"Debut de l'etude"}
          errorState={errorState.etude_begin_date}
          onChange={onValueChange("etude_begin_date")}
          error={errorState.etude_begin_date.state}
        />
        <DateInput
          label={"Fin de l'etude"}
          errorState={errorState.etude_final_date}
          onChange={onValueChange("etude_final_date")}
          error={errorState.etude_final_date.state}
        />
      </div>
      {formData.etude_data.length !== 0 && (
        <div className="flex flex-col gap-2 w-full">
          {formData.etude_data.map((e, index) => {
            return (
              <div
                key={index}
                className="flex gap-3 font-medium text-[14px] items-center"
              >
                {e.uploading ? (
                  <svg
                    className="animate-spin h-4 w-4 text-black/50"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={"transparent"}
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke={primaryColor}
                      strokeWidth="2"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill={primaryColor}
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : e.uploaded ? (
                  e.success ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4 text-primary"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4 text-red-500 cursor-pointer hover:text-red-300"
                      onClick={() => {
                        removeEtudeData(index);
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 cursor-pointer hover:text-hover"
                    onClick={() => {
                      removeEtudeData(index);
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                )}
                <span className="flex select-none">{e.name}</span>
              </div>
            );
          })}
        </div>
      )}
      {!isLoading && (
        <button
          onClick={pickFile}
          disabled={isLoading}
          className="flex py-2 rounded-[3px] w-full gap-2 border border-gainsboro hover:border-hover items-center justify-center"
          style={{
            color: errorState.etude_data.state ? "red" : "black",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span className="flex flex-col text-center">
            <span className="flex">
              Cliquer ici pour ajouter un fichier d'etude
            </span>
          </span>
        </button>
      )}
      <span className="flex flex-col text-[14px] text-black/80 w-full items-center">
        <span>Vous pouvez uniquement envoyer les fichiers de type :</span>
        <b>.png,.jpg,.jpeg,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.csv</b>
      </span>
      <input
        id="fileType"
        type="file"
        accept=".png ,.jpg ,.jpeg ,.pdf ,.xls ,.xlsx ,.doc ,.docx ,.ppt ,.pptx ,.csv"
        className="hidden w-0 h-0"
        multiple={true}
        onChange={handleChange}
      />
    </div>
  );
};

export default Form;
