import React from "react";
import { LogsContext } from "./context";
import { useAuthDataHook } from "../authData/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { AdminObject, LogObject } from "../../types/models";
import { LoadingCircle } from "../../pages/pageDataLoading";
import { getAdminAllLogs } from "./functions";
import { useStateBasicCryptSave } from "../basicdata/hooks";

const LogsProvider = ({ children = <div /> }) => {
  const { admins } = useAuthDataHook();
  const { adminId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = React.useState(true);
  const [admin, setAdmin] = React.useState(AdminObject);
  const [logs, setLogs] = useStateBasicCryptSave(
    "logs-" + adminId,
    Array<typeof LogObject>()
  );
  function refreshLogs() {
    getAdminAllLogs({ admin_id: admin.admin_id }, function (data) {
      setLogs(data.data);
    });
  }

  function getLogs(admin: any) {
    getAdminAllLogs({ admin_id: admin.admin_id }, function (data) {
      setLogs(data.data);
      setisLoading(false);
    });
  }

  React.useEffect(() => {
    const id = adminId;
    const admin = admins.filter((e) => e.admin_id === id)[0];
    if (id && admin) {
      setAdmin(admin);
      getLogs(admin);
    } else {
      navigate("/dashboard/utilisateurs");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admins, adminId]);

  return (
    <LogsContext.Provider value={{ admin, logs, refreshLogs }}>
      {isLoading ? <LoadingCircle /> : children}
    </LogsContext.Provider>
  );
};

export default LogsProvider;
