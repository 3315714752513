import React from "react";
import { ModalContainer } from "../../provider";
import { useModalsHook } from "../../hooks";
import { useModalHook } from "../../../../functions/useModalHook";

const ValidateAction = () => {
  const { closeModal } = useModalHook();
  const { data } = useModalsHook();
  return (
    <div className="flex flex-col items-center pb-6 pt-7 px-10 gap-5 bg-white rounded-md shadow-md ">
      <span className="flex text-black font-semibold text-[20px] w-[84%] text-center">
        {!!!data?.confirmationText
          ? "Etes-vous sur de cette action ?"
          : data.confirmationText}
      </span>
      <div className="flex flex-row gap-4">
        <button
          onClick={function () {
            data.confirm();
            closeModal();
          }}
          className="flex px-12 py-2 bg-primary hover:bg-hover active:bg-active rounded-md"
        >
          <span className="flex text-white">
            {!!!data?.confirmationButtonText
              ? "Confirmer"
              : data.confirmationButtonText}
          </span>
        </button>
        <button
          onClick={closeModal}
          className="flex px-12 py-2 text-black bg-gainsboro hover:bg-primary hover:text-white active:bg-primary/50 rounded-md"
        >
          <span className="flex ">Annuler</span>
        </button>
      </div>
    </div>
  );
};

const ValidateActionFromModel = ({ size = { h: 0, w: 0 } }) => {
  return (
    <ModalContainer {...{ size }}>
      <ValidateAction />
    </ModalContainer>
  );
};

export default ValidateActionFromModel;
