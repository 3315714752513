import axios from "axios";
import { request } from "../configs/UrlBases";
import { decryptDataAesGcm } from "../configs/gcm-decrypt";
import { encryptDataAesGcm } from "../configs/gcm-encrypt";
import { decryptKey, encryptKey } from "../configs/rsa";

function decryptDataFromServer(data = { kd: "", dt: "" }) {
  try {
    const key = decryptKey(data.kd);
    const resolved = decryptDataAesGcm(key, data.dt);
    return resolved;
  } catch (error) {
    return { data: data };
  }
}

function encryptDataToServer(data: any = "0") {
  try {
    const aesEncrypt = encryptDataAesGcm(data);
    const keyEnc = encryptKey(aesEncrypt.encryptionKey);
    return { kd: keyEnc, dt: aesEncrypt.data };
  } catch (error) {
    return { kd: "", dt: "" };
  }
}

export function AxiosGetUrl(
  url: string,
  cb = function (reponse: any) {},
  err = function (reponse?: any) {}
) {
  let autho = "";
  const configuration = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${autho}`,
      tremplin: "web_admin",
    },
  };
  request
    .get(url, configuration)
    .then((res) => {
      if (res.data) {
        cb && cb(decryptDataFromServer(res.data).data);
      }
    })
    .catch((e) => {
      err && err();
    });
}

export function AxiosPostUrl(
  url: string,
  data: Array<any> | Object,
  cb = function (reponse: any) {},
  err = function (reponse?: any) {}
) {
  let autho = "";
  const encrypted = encryptDataToServer(data);
  const configuration = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${autho}`,
      tremplin: "web_admin",
      kd: encrypted.kd,
    },
  };
  return request
    .post(url, { dt: encrypted.dt }, configuration)
    .then((reponse) => {
      if (reponse.data) {
        cb(decryptDataFromServer(reponse.data).data);
        return decryptDataFromServer(reponse.data).data;
      }
    })
    .catch((e) => {
      err && err();
    });
}

export function AxiosPostUrlData(
  url: string,
  data: any,
  config: any,
  cb = function (reponse: any) {},
  err = function (reponse?: any) {}
) {
  return axios
    .post(url, data, config)
    .then((reponse) => {
      if (reponse.data) {
        cb(reponse.data);
        return reponse.data;
      }
    })
    .catch((e) => {
      err && err(e);
    });
}
