import { Autocomplete } from "@mui/material";
import TextInput from "./input";
import { ANY } from "../../constants";

interface Option {
  label: string;
  value: any;
}

interface Props {
  onChange?: (value: any) => void;
  errorState?: { state: boolean; msg: string };
  options?: Option[];
  defaultOption?: Option;
  selectedOption?: Option;
  [x: string]: any;
}

function AutocompleteSelectInput({
  onChange = (value: any) => {},
  errorState = { state: false, msg: "" },
  options = [],
  defaultOption,
  selectedOption,
  ...args
}: Props) {
  return (
    <Autocomplete
      className="self-stretch"
      disablePortal
      value={selectedOption}
      options={options}
      onChange={(event, value) => {
        onChange(value?.value ?? defaultOption?.value ?? ANY);
      }}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) =>
        option.label === (value ? value.label : "")
      }
      renderInput={(params: any) => (
        <TextInput
          {...params}
          color="warning"
          variant="outlined"
          error={errorState.state}
          helperText={errorState.msg || null}
          className="flex bg-white border-none outline-none"
          {...args}
        />
      )}
      size="medium"
    />
  );
}

export default AutocompleteSelectInput;
