import TextInput from "../../components/inputs/input";
import PasswordInput from "../../components/inputs/password";
import { useSignInHook } from "../../contexts/signin/hooks";

const SignIn = () => {
  const { errorState, isLoading, onValueChange, submitForm } = useSignInHook();
  return (
    <div className="flex flex-1 justify-center items-start">
      <form
        className="flex flex-col w-[350px] gap-4 py-10"
        onSubmit={(e) => {
          e.preventDefault();
          !isLoading && submitForm();
          if (isLoading) {
            alert(
              "Veuillez patienter pendant que nous établissons la connexion. Cette opération devrait prendre un court instant."
            );
          }
        }}
      >
        <span className="flex flex-col text-[20px] text-black font-semibold text-center">
          <span>Connectez-vous</span>
          <span>à votre tableau de bord</span>
        </span>
        <TextInput
          label="Email"
          errorState={errorState.login}
          onChange={onValueChange("login")}
        />
        <PasswordInput
          label="Mot de passe"
          errorState={errorState.password}
          onChange={onValueChange("password")}
        />

        {!!errorState.generalMsg && (
          <div className="flex w-full">
            <span className="flex text-[15px] font-medium text-red-600">
              {errorState.generalMsg}
            </span>
          </div>
        )}
        <a href="/mot-de-passe-oublie" className="group flex">
          <span className="flex text-primary group-hover:text-hover font-medium text-[13px]">
            Mot de passe oublie ?
          </span>
        </a>
        <button
          type="submit"
          className={`flex ${
            !isLoading ? "bg-primary hover:bg-hover" : "bg-hover"
          }  items-center justify-center py-[16px]`}
        >
          <span className="flex text-white font-semibold text-[18px]">
            {isLoading ? "Connection...." : "Connecter"}
          </span>
        </button>
        <span className="flex flex-col text-[13px] text-gray text-center">
          <span>En créant ou en vous connectant à un compte,</span>
          <span>
            vous acceptez nos conditions générales et Politique de
            confidentialité.
          </span>
        </span>
      </form>
    </div>
  );
};

export default SignIn;
