import React from "react";
import LogoBlanc from "../../assets/LogoBlanc.svg";
import { useAuthHook } from "../../contexts/auth/hooks";
import ConnectedHeader from "./connected";
import BasicHeader from "./basic";

const Header = () => {
  const { connected } = useAuthHook();
  return (
    <div
      className={`flex h-[60px] w-full overflow-hidden items-center justify-center text-white bg-primary px-10 fixed z-[0] top-0 left-0`}
    >
      <div className={`flex flex-row justify-between w-full`}>
        <div className="flex flex-row gap-2 items-center">
          <a href="/" className="flex">
            <img className="w-[166px]" alt="" src={LogoBlanc} />
          </a>
          <div className="flex bg-white w-px h-[25px]" />
          <span>Administration</span>
        </div>
        {connected ? <ConnectedHeader /> : <BasicHeader />}
      </div>
    </div>
  );
};

export default Header;
