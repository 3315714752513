import { createContext } from "react";

export const UpdateClientContext = createContext({
  isLoading: false,
  formData: {
    client_name: "",
  },
  onValueChange: (slug: string) => function (value: any) {},
  submitForm: function () {},
  errorState: {
    client_name: { state: false, msg: "" },
  },
});
