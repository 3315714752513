import { createContext } from "react";

export const CreateProjetContext = createContext({
  isLoading: false,
  formData: {
    client_id: "",
    projet_title: "",
    projet_description: "",
  },
  onValueChange: (slug: string) => function (value: any) {},
  submitForm: function () {},
  errorState: {
    client_id: { state: false, msg: "" },
    projet_title: { state: false, msg: "" },
    projet_description: { state: false, msg: "" },
  },
});
