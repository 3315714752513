import { createContext } from "react";
import {
  AdminObject,
  ClientObject,
  EtudeObject,
  ProjetObject,
} from "../../types/models";

export const AuthDataContext = createContext({
  sidebarItems: Array<{
    img: ({
      color,
      ...args
    }: {
      [x: string]: any;
      color?: string | undefined;
    }) => JSX.Element;
    title: string;
    url: string;
    slug: string;
    content: JSX.Element;
    subroute?: Array<{
      img: ({
        color,
        ...args
      }: {
        [x: string]: any;
        color?: string | undefined;
      }) => JSX.Element;
      title: string;
      url: string;
      slug: string;
      content: JSX.Element;
    }>;
  }>(),
  admins: Array<typeof AdminObject>(),
  clients: Array<typeof ClientObject>(),
  projets: Array<typeof ProjetObject>(),
  etudes: Array<typeof EtudeObject>(),
  refreshClients: () => {},
  refreshProjets: () => {},
  refreshEtudes: () => {},
  refreshAdmins: () => {},
});
