import React from "react";

const InfoIcon = ({ color = "", ...args }) => {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      {...args}
    >
      <path
        fillRule={"evenodd"}
        clipRule={color}
        d="M5.95078 2.56382C6.45237 1.14468 7.8058 0.12793 9.3967 0.12793H11.8326C13.4235 0.12793 14.7769 1.14468 15.2785 2.56382H16.7044C18.7223 2.56382 20.3582 4.19969 20.3582 6.21765V20.833C20.3582 22.8509 18.7223 24.4868 16.7044 24.4868H4.52492C2.50697 24.4868 0.871094 22.8509 0.871094 20.833V6.21765C0.871094 4.19969 2.50697 2.56382 4.52492 2.56382H5.95078ZM5.95078 4.9997H4.52492C3.85227 4.9997 3.30698 5.54499 3.30698 6.21765V20.833C3.30698 21.5056 3.85227 22.0509 4.52492 22.0509H16.7044C17.377 22.0509 17.9223 21.5056 17.9223 20.833V6.21765C17.9223 5.54499 17.377 4.9997 16.7044 4.9997H15.2785C14.7769 6.41884 13.4235 7.43559 11.8326 7.43559H9.3967C7.8058 7.43559 6.45237 6.41884 5.95078 4.9997ZM9.3967 2.56382C8.72404 2.56382 8.17875 3.10911 8.17875 3.78176C8.17875 4.45441 8.72404 4.9997 9.3967 4.9997H11.8326C12.5052 4.9997 13.0505 4.45441 13.0505 3.78176C13.0505 3.10911 12.5052 2.56382 11.8326 2.56382H9.3967ZM5.74287 12.3074C5.74287 11.6347 6.28816 11.0894 6.96081 11.0894H6.97299C7.64564 11.0894 8.19093 11.6347 8.19093 12.3074C8.19093 12.98 7.64564 13.5253 6.97299 13.5253H6.96081C6.28816 13.5253 5.74287 12.98 5.74287 12.3074ZM9.3967 12.3074C9.3967 11.6347 9.94199 11.0894 10.6146 11.0894H14.2685C14.9411 11.0894 15.4864 11.6347 15.4864 12.3074C15.4864 12.98 14.9411 13.5253 14.2685 13.5253H10.6146C9.94199 13.5253 9.3967 12.98 9.3967 12.3074ZM5.74287 17.1791C5.74287 16.5065 6.28816 15.9612 6.96081 15.9612H6.97299C7.64564 15.9612 8.19093 16.5065 8.19093 17.1791C8.19093 17.8518 7.64564 18.3971 6.97299 18.3971H6.96081C6.28816 18.3971 5.74287 17.8518 5.74287 17.1791ZM9.3967 17.1791C9.3967 16.5065 9.94199 15.9612 10.6146 15.9612H14.2685C14.9411 15.9612 15.4864 16.5065 15.4864 17.1791C15.4864 17.8518 14.9411 18.3971 14.2685 18.3971H10.6146C9.94199 18.3971 9.3967 17.8518 9.3967 17.1791Z"
        fill={color}
      />
    </svg>
  );
};

export default InfoIcon;
