import React from "react";
import { CreateEtudeContext } from "./context";
import { useAuthDataHook } from "../authData/hooks";
import { useModalHook } from "../../functions/useModalHook";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { addEtudeFiles, addNewEtude, uploadEtudeData } from "./functions";
import { useModalsHook } from "../modals/hooks";
import { chunkFile, compressChunk } from "../../constants";
import { useAuthHook } from "../auth/hooks";

const CreateEtudeProvider = ({ children = <div /> }) => {
  const { admin } = useAuthHook();
  const { closeModal } = useModalHook();
  const { data, setData } = useModalsHook();
  const { refreshClients, refreshProjets, refreshEtudes } = useAuthDataHook();
  const navigate = useNaviLoadBoth;
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    client_id: "",
    projet_id: "",
    etude_title: "",
    etude_descripton: "",
    etude_begin_date: "",
    etude_final_date: "",
    etude_data: Array<any>(),
  });
  const [errorState, setErrorState] = React.useState({
    etude_title: { state: false, msg: "" },
    etude_descripton: { state: false, msg: "" },
    etude_data: { state: false, msg: "" },
    etude_begin_date: { state: false, msg: "" },
    etude_final_date: { state: false, msg: "" },
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...f, [slug]: value }));
      setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
    };
  }

  function getFormData(file: any, callback = (...args: any) => {}) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const formData = {
        name: file.name,
        type: file.name.split(".").reverse()[0],
        data: reader.result,
        file: file,
        uploaded: false,
        uploading: false,
        success: false,
      };
      callback(null, formData);
    };

    reader.onerror = () => {
      console.error("Something went wrong!");
      callback(new Error("File reading error"));
    };
  }
  function addEtudeData(data: any) {
    getFormData(data, (err, formdt) => {
      setFormData((f) => ({ ...f, etude_data: f.etude_data.concat(formdt) }));
      setErrorState((e) => ({ ...e, etude_data: { state: false, msg: "" } }));
    });
  }
  function removeEtudeData(index: number) {
    setFormData((f) => ({
      ...f,
      etude_data: f.etude_data.filter((e, i) => i !== index),
    }));
  }
  function checkFormData() {
    let errorOccured = false;
    // title
    if (formData.etude_title.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, etude_title: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, etude_title: { state: false, msg: "" } }));
    }
    // description
    if (formData.etude_descripton.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, etude_descripton: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        etude_descripton: { state: false, msg: "" },
      }));
    }
    // description
    if (formData.etude_begin_date.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, etude_begin_date: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        etude_begin_date: { state: false, msg: "" },
      }));
    }
    // description
    if (formData.etude_final_date.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, etude_final_date: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        etude_final_date: { state: false, msg: "" },
      }));
    }
    // file
    if (formData.etude_data.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({
        ...e,
        etude_data: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        etude_data: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function submitForm() {
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setisLoading(true);
    setData({ ...data, isclosable: false });
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));

    const datas = formData.etude_data.map((f: any, index) => ({
      name: f.name.split(".").reverse().slice(1).reverse().join("."),
      type: f.type,
      file: f.file,
    }));
    async function uploadFilesSequentially(datas: Array<any>, etudeId: string) {
      let rponse = [];
      for (let idx = 0; idx < datas.length; idx++) {
        const data = datas[idx];
        const tempPartsFolder = data.name + "-tempDoc";
        let res = await new Promise(async (resolve, reject) => {
          setFormData((f) => ({
            ...f,
            etude_data: f.etude_data.map((dt: any, index) => {
              if (index === idx) {
                return { ...dt, uploading: true };
              }
              return dt;
            }),
          }));
          const fileChunks = chunkFile(data.file, 2 * 1024 * 1024);
          const compressedChunks = await Promise.all(
            fileChunks.map((chunk) => compressChunk(chunk))
          );
          async function sendChunks(
            fileChunks: Array<any>,
            fileName: any,
            maxConcurrency = 5
          ) {
            const totalChunks = fileChunks.length;
            let currentChunkIndex = 0;

            function createTask(chunk: any, chunkIndex: number) {
              return async () => {
                try {
                  const formDa = new FormData();
                  formDa.append("chunkIndex", chunkIndex.toString());
                  formDa.append("totalChunks", totalChunks.toString());
                  formDa.append("fileName", fileName);
                  formDa.append("tempPartsFolder", tempPartsFolder);
                  formDa.append(
                    "folderKey",
                    `InsightPlusAfrica/uploads/clients/${formData.client_id}/etudes/${etudeId}`
                  );
                  formDa.append("file", chunk);
                  await uploadEtudeData(formDa, function (reponse) {
                    if (reponse?.data?.file_id) {
                      setFormData((f) => ({
                        ...f,
                        etude_data: f.etude_data.map((dt: any, index) => {
                          if (index === idx) {
                            return {
                              ...dt,
                              uploaded: true,
                              uploading: false,
                              success: !!reponse.data.file_id,
                            };
                          }
                          return dt;
                        }),
                      }));
                      resolve({
                        file_id: reponse.data.file_id,
                        download_url: reponse.data.download_url,
                        name: data.name,
                        type: data.type,
                      });
                    }
                  });
                } catch (err) {
                  // console.log(`Error uploading chunk ${chunkIndex}:`, err);
                  return createTask(chunk, chunkIndex); // Return a new task to retry
                }
              };
            }

            const tasks = fileChunks.map((chunk: any, chunkIndex) =>
              createTask(chunk, chunkIndex)
            );

            while (currentChunkIndex < tasks.length) {
              const activeTasks = tasks.slice(
                currentChunkIndex,
                currentChunkIndex + maxConcurrency
              );
              await Promise.all(activeTasks.map((task) => task()));
              currentChunkIndex += maxConcurrency;
            }
          }
          sendChunks(compressedChunks, data.file.name);
        });
        if (res) {
          rponse.push(res);
        }
      }
      return rponse;
    }
    addNewEtude(
      {
        id: admin.admin_id,
        client_id: formData.client_id,
        projet_id: formData.projet_id,
        etude_title: formData.etude_title,
        etude_descripton: formData.etude_descripton,
        etude_begin_date: formData.etude_begin_date,
        etude_final_date: formData.etude_final_date,
        etude_data: [],
      },
      function (reponse) {
        if (reponse.success) {
          const etudeId = reponse.data.etude;

          uploadFilesSequentially(datas, etudeId)
            .then(function (e) {
              const dts = e.filter((r: any) => !!r.file_id);
              if (dts.length !== 0) {
                addEtudeFiles(
                  { id: admin.admin_id, etude_id: etudeId, data: dts },
                  function (dat) {
                    setisLoading(false);
                    setData({ ...data, isclosable: true });
                    refreshClients();
                    refreshProjets();
                    refreshEtudes();
                    closeModal();
                  }
                );
              } else {
                setFormData((f) => ({
                  ...f,
                  etude_data: f.etude_data.map((dt: any, index) => {
                    return {
                      ...dt,
                      uploaded: true,
                      uploading: false,
                      success: false,
                    };
                  }),
                }));
                setisLoading(false);
                refreshClients();
                refreshProjets();
                refreshEtudes();
                alert("Aucun fichier charger !");
              }
            })
            .catch(function () {
              console.log("erreur");
              setisLoading(false);
              setData({ ...data, isclosable: true });
              refreshClients();
              refreshProjets();
              refreshEtudes();
              closeModal();
            });
        } else {
          setisLoading(false);
          setData({ ...data, isclosable: true });
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
          if (reponse?.redirection) {
            navigate(reponse.redirection);
          }
        }
      }
    );
  }
  return (
    <CreateEtudeContext.Provider
      value={{
        formData,
        isLoading,
        errorState,
        addEtudeData,
        removeEtudeData,
        onValueChange,
        submitForm,
      }}
    >
      {children}
    </CreateEtudeContext.Provider>
  );
};

export default CreateEtudeProvider;
