import React from "react";
import TextInput from "../../../../components/inputs/input";
import { useUpdateClientHook } from "../../../updateClient/hooks";

const Form = () => {
  const { onValueChange, errorState } = useUpdateClientHook();
  return (
    <div className="flex flex-col gap-4 w-full py-3">
      <TextInput
        label={"Nom de l'entreprise"}
        errorState={errorState.client_name}
        onChange={onValueChange("client_name")}
      />
    </div>
  );
};

export default Form;
