import React from "react";
import Header from "./header";
import BlocsRow from "./bocsrow";
import TableDataAutoList from "../../../../components/array";
import { useAuthDataHook } from "../../../../contexts/authData/hooks";

const DashboardHome = () => {
  const { clients, projets, etudes } = useAuthDataHook();
  const columnsRecenteReservations = [
    {
      label: "Client",
      slug: "customer",
    },
    {
      label: "Projets",
      slug: "projects",
    },
    {
      label: "Etudes",
      slug: "etudes",
    },
  ];
  return (
    <div className="flex flex-1 flex-col gap-5">
      <Header />
      <BlocsRow />
      <div className="flex flex-col mt-3 gap-3">
        <span className="flex text-[17px] font-normal">
          Récapitulatif de vos réalisations
        </span>
        <TableDataAutoList
          columns={columnsRecenteReservations}
          rows={clients.map((client) => ({
            customer: client.client_name,
            projects: projets.filter((p) => p.client_id === client.client_id).length.toString(),
            etudes: etudes.filter((e) => e.client_id === client.client_id).length.toString(),
          }))}
        />
      </div>
    </div>
  );
};

export default DashboardHome;
