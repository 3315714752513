import { createContext } from "react";
import { CountryObject, LangObject } from "../../types/models";

export const BasicDataContext = createContext({
  //country
  countries: Array<typeof CountryObject>(),
  refreshCountries: function () {},
  localCountry: CountryObject,
  setLocalCountry: function (country = CountryObject) {},
  setLocalCountryFromCode: function (code = "") {},
  //lang
  selectedLang: LangObject,
  langs: Array<typeof LangObject>(),
  setLang: function (lang = LangObject) {},
  refreshLangs: function () {},
});
