import React from "react";
import TableDataAutoList from "../../../../components/array";
import { RemoveReferant } from "../../../../contexts/client/functions";
import { useModalHook } from "../../../../functions/useModalHook";
import { useModalsHook } from "../../../../contexts/modals/hooks";
import { useClientHook } from "../../../../contexts/client/hook";
import { useAuthHook } from "../../../../contexts/auth/hooks";

const Table = () => {
  const { admin } = useAuthHook();
  const { referant, refreshClient } = useClientHook();
  const { setModal } = useModalHook();
  const { setData } = useModalsHook();
  const columns = [
    {
      label: "Nom",
      slug: "name",
    },
    {
      label: "Email du référant",
      slug: "email",
      maxValueLenght: 40,
    },
    {
      label: "Modifier",
      slug: "edit",
    },
    {
      label: "Supprimer",
      slug: "remove",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        onEdit={function (data) {
          setData({
            referant:data.referant,
          });
          setModal("update_referant");
        }}
        onRemove={function (data) {
          setData({
            confirm: function () {
              RemoveReferant(
                {
                  id: admin.admin_id,
                  referant_id: data.referant.referant_id,
                },
                function (reponse) {
                  refreshClient();
                }
              );
            },
          });
          setModal("confirm_action");
        }}
        rows={
          referant.referant_id
            ? [
                {
                  referant,
                  name: referant.referant_name,
                  email: referant.referant_email,
                },
              ]
            : []
        }
      />
    </div>
  );
};

export default Table;
