import React from "react";
import TextInput from "../../../../components/inputs/input";
import ProjectsArray from "./projectsArray";
import { useModalHook } from "../../../../functions/useModalHook";

const ProjetsContent = () => {
  const { setModal } = useModalHook();
  const [search, setSearch] = React.useState("");
  return (
    <div className="flex flex-1 flex-col gap-10">
      <div className="flex h-fit flex-row justify-between items-center">
        <span className="flex text-[22px] font-medium">
          Liste de tous les Projets
        </span>
        <button
          onClick={() => {
            setModal("create_projet");
          }}
          className="flex px-12 py-2 bg-primary hover:bg-hover active:bg-active rounded-md"
        >
          <span className="flex text-white">Nouveau projet</span>
        </button>
      </div>
      <div className="flex flex-col h-[40px] w-[40%]">
        <TextInput
          placeholder={"Chercher un projet..."}
          onChange={(s) => {
            setSearch(s);
          }}
        />
      </div>
      <ProjectsArray search={search} />
    </div>
  );
};

export default ProjetsContent;
