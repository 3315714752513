import { AxiosPostUrl } from "../../functions/axiosfunctions";

export function sendAdminSignupData(data: Object, cb = function (reponse: any) {}) {
  AxiosPostUrl(
    "/administration/admin/new",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}

export function SwitchAdminState(
  data: Object,
  cb = function (reponse: any) {}
) {
  AxiosPostUrl(
    "/administration/admin/state/switch",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}

export function RemoveAdmin(
  data: Object,
  cb = function (reponse: any) {}
) {
  AxiosPostUrl(
    "/administration/admin/remove",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}
