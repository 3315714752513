import React from "react";
import { CreateAdminContext } from "./context";
import { sendAdminSignupData } from "./functions";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { isEmail } from "../../constants";
import { useAuthDataHook } from "../authData/hooks";
import { useModalHook } from "../../functions/useModalHook";
import { useModalsHook } from "../modals/hooks";
import { useAuthHook } from "../auth/hooks";

const CreateAdminProvider = ({ children = <div /> }) => {
  const { admin } = useAuthHook();
  const { closeModal } = useModalHook();
  const { data, setData } = useModalsHook();
  const { refreshClients, refreshProjets, refreshEtudes, refreshAdmins } =
    useAuthDataHook();
  const navigate = useNaviLoadBoth;
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    admin_name: "",
    admin_email: "",
    admin_password: "",
  });
  const [errorState, setErrorState] = React.useState({
    admin_name: { state: false, msg: "" },
    admin_email: { state: false, msg: "" },
    admin_password: { state: false, msg: "" },
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...f, [slug]: value }));
      setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
    };
  }
  function checkFormData() {
    let errorOccured = false;
    // admin_name
    if (formData.admin_name.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, admin_name: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, admin_name: { state: false, msg: "" } }));
    }
    // admin_email
    if (formData.admin_email.length === 0 || !isEmail(formData.admin_email)) {
      errorOccured = true;
      let msg = "adresse email incorrecte";
      setErrorState((e) => ({ ...e, admin_email: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        admin_email: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function submitForm() {
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setisLoading(true);
    setData({ ...data, isclosable: false });
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    sendAdminSignupData(
      {
        id: admin.admin_id,
        admin_name: formData.admin_name,
        admin_email: formData.admin_email,
        admin_password: formData.admin_password,
      },
      function (reponse) {
        setisLoading(false);
        setData({ ...data, isclosable: true });
        if (reponse.success) {
          refreshAdmins();
          refreshClients();
          refreshProjets();
          refreshEtudes();
          closeModal();
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
          if (reponse?.redirection) {
            navigate(reponse.redirection);
          }
        }
      }
    );
  }
  return (
    <CreateAdminContext.Provider
      value={{ formData, isLoading, errorState, onValueChange, submitForm }}
    >
      {children}
    </CreateAdminContext.Provider>
  );
};

export default CreateAdminProvider;
