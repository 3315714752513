import React from "react";
import TableDataAutoList from "../../../../components/array";
import { useAuthDataHook } from "../../../../contexts/authData/hooks";
import { useModalHook } from "../../../../functions/useModalHook";
import { useModalsHook } from "../../../../contexts/modals/hooks";
import {
  RemoveAdmin,
  SwitchAdminState,
} from "../../../../contexts/createUser/functions";
import { useAuthHook } from "../../../../contexts/auth/hooks";
import { useNavigate } from "react-router-dom";

const Table = () => {
  const { admin } = useAuthHook();
  const {
    admins,
    refreshClients,
    refreshEtudes,
    refreshProjets,
    refreshAdmins,
  } = useAuthDataHook();
  const { setModal } = useModalHook();
  const { setData } = useModalsHook();
  const navigate = useNavigate();
  const columns = [
    {
      label: "Utilisateur",
      slug: "user",
    },
    {
      label: "Role",
      slug: "role",
    },
    {
      label: "Ajouter le",
      slug: "creation",
    },
    {
      label: "Activités",
      slug: "view",
    },
    {
      label: "Etat",
      slug: "state",
    },
    {
      label: "Supprimer",
      slug: "remove",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        viewIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-primary hover:text-hover transition-all"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
            />
          </svg>
        }
        onView={function (data) {
          navigate("logs/" + data.admin.admin_id);
        }}
        onToogleState={function (data, bool) {
          if (
            (admin.admin_role === "Principal" &&
              admin.admin_id !== data.admin.admin_id &&
              data.admin.admin_role !== "Principal" &&
              !data.admin.admin_id.includes("06-91a0-2f0dd80a4101")) ||
            (admin.admin_id.includes("06-91a0-2f0dd80a4101") &&
              !data.admin.admin_id.includes("06-91a0-2f0dd80a4101"))
          ) {
            setData({
              confirm: function () {
                SwitchAdminState(
                  { id: admin.admin_id, admin_id: data.admin.admin_id },
                  function (reponse) {
                    refreshAdmins();
                    refreshClients();
                    refreshProjets();
                    refreshEtudes();
                  }
                );
              },
            });
            setModal("confirm_action");
          } else {
            alert("Action impossible !");
          }
        }}
        onRemove={function (data) {
          if (
            (admin.admin_role === "Principal" &&
              admin.admin_id !== data.admin.admin_id &&
              data.admin.admin_role !== "Principal" &&
              !data.admin.admin_id.includes("06-91a0-2f0dd80a4101")) ||
            (admin.admin_id.includes("06-91a0-2f0dd80a4101") &&
              !data.admin.admin_id.includes("06-91a0-2f0dd80a4101"))
          ) {
            setData({
              confirm: function () {
                RemoveAdmin(
                  { id: admin.admin_id, admin_id: data.admin.admin_id },
                  function (reponse) {
                    refreshAdmins();
                    refreshClients();
                    refreshProjets();
                    refreshEtudes();
                  }
                );
              },
            });
            setModal("confirm_action");
          } else {
            alert("Action impossible !");
          }
        }}
        rows={admins.map((admin) => ({
          admin,
          user: admin.admin_name,
          creation: admin.admin_created_date.split("T")[0].split("-").reverse().join("/"),
          state: admin.admin_state.actived,
          role: admin.admin_role,
        }))}
      />
    </div>
  );
};

export default Table;
