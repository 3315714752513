import React from "react";
import { ModalContainer } from "../../provider";
import Form from "./form";
import CreateEtudeProvider from "../../../createEtude/provider";
import { useCreateEtudeHook } from "../../../createEtude/hooks";

const CreateEtude = () => {
  const { isLoading, submitForm } = useCreateEtudeHook();
  return (
    <div className="flex flex-col max-h-[84vh] items-center pb-6 pt-3 px-10 pr-7 gap-3 bg-white rounded-md w-[500px] shadow-md  overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/40 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
      <span className="flex text-primary font-semibold text-[20px]">
        Nouvelle etude
      </span>
      <Form />
      <button
        onClick={!isLoading ? submitForm : void {}}
        className="flex px-12 py-2 bg-primary hover:bg-hover active:bg-active rounded-md"
        style={{
          background: isLoading ? "rgba(249, 154, 14, 0.75)" : undefined,
        }}
      >
        <span className="flex text-white">
          {isLoading ? "chargement..." : "Enregister l'etude"}
        </span>
      </button>
    </div>
  );
};

const CreateEtudeFromModel = ({ size = { h: 0, w: 0 } }) => {
  return (
    <ModalContainer {...{ size }}>
      <CreateEtudeProvider>
        <CreateEtude />
      </CreateEtudeProvider>
    </ModalContainer>
  );
};

export default CreateEtudeFromModel;
