import React from "react";
import { useAuthDataHook } from "../authData/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ClientObject, ReferantObject } from "../../types/models";
import { ClientContext } from "./context";

const ClientProvider = ({ children = <div /> }) => {
  const { refreshClients, clients } = useAuthDataHook();
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = React.useState(ClientObject);
  const [referant, setReferant] = React.useState(ReferantObject);
  function refreshClient() {
    refreshClients();
  }

  React.useEffect(() => {
    const id = clientId;
    const client = clients.filter((e) => e.client_id === id)[0];
    if (id && client) {
      setClient(client);
      setReferant(client.referant);
    } else {
      navigate("/dashboard/clients");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, clientId]);

  return (
    <ClientContext.Provider value={{ client, referant, refreshClient }}>
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;
