import { AxiosPostUrl, AxiosPostUrlData } from "../../functions/axiosfunctions";

export function addNewEtude(data: Object, cb = function (reponse: any) {}) {
  AxiosPostUrl(
    "/administration/etude/new",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}

export async function uploadEtudeData(
  data: any,
  cb = function (reponse: any) {}
) {
  return await AxiosPostUrlData(
    `https://cloudservices.awanze.com/v1/store/manage/upload`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
    (reponse) => {
      if (reponse) {
        cb({ ...reponse, success: true });
      } else {
        cb({
          success: false,
          data: reponse,
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: reponse,
        msg: "Erreur survenue !",
      });
    }
  );
}

export function addEtudeFiles(data: Object, cb = function (reponse: any) {}) {
  AxiosPostUrl(
    "/administration/etude/update/files",
    data,
    (reponse) => {
      if (reponse) {
        cb(reponse);
      } else {
        cb({
          success: false,
          data: {},
          msg: "Erreur survenue !",
        });
      }
    },
    function (reponse) {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  );
}
