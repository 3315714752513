import React from "react";
import { useAuthHook } from "../../../../contexts/auth/hooks";

const Header = () => {
  const {admin} = useAuthHook()
  return (
    <div className="flex flex-row items-center justify-between">
      <span className="flex font-medium text-[18px]">
        Bienvenue sur le Tableau de bord
      </span>
      <div className="flex flex-row gap-5 items-center">
        <span className="flex font-semibold text-[18px]">{admin.admin_name}</span>
      </div>
    </div>
  );
};

export default Header;
