import React from "react";
import { useLogsHook } from "../../../../contexts/logs/hooks";
import Table from "./table";

const LogsContent = () => {
  const { admin } = useLogsHook();
  return (
    <div className="flex flex-1 flex-col gap-5">
      <div className="flex flex-row gap-3 items-center">
        <span className="flex text-[15px] font-semibold">Utilisateur :</span>
        <span className="flex text-[25px] font-semibold">
          {admin.admin_name}
        </span>
      </div>
      <div className="flex flex-row gap-3 items-center">
        <span className="flex text-[16px] font-semibold">
          Rapport d'activite de l'utilisateur dans la console d'administration.
        </span>
      </div>
      <Table />
    </div>
  );
};

export default LogsContent;
